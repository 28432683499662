import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  Title,
  StepWrapper,
  StepContent,
} from './MarketingComponents/StyledComponents';
import StepOne from './MarketingComponents/StepOne';
import OutputDisplay from './MarketingComponents/OutputDisplay';
import '@fontsource/raleway';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Raleway', sans-serif;
    background-color: #FFF9C4;
  }

  * {
    box-sizing: border-box;
  }
`;

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.div`
  text-align: center;
  width: 100%;
  max-width: 800px;
`;

const Subtitle = styled.p`
  margin-top: 1rem;
  font-size: 1.125rem;
  color: #4a5568;
  line-height: 1.5;
`;

const ContentRow = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  align-items: stretch;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1;
  width: 100%;
  min-width: 0; // Prevents flex items from overflowing
`;

const LeftColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const UsageInfo = styled.div`
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin-top: 1rem;
`;

const InfoCardWrapper = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  flex-grow: 1;
`;

const ExampleCard = styled.div`
  background-color: #ebf8ff;
  border-radius: 8px;
  padding: 1rem;
  text-align: left;

  p {
    margin: 0;
    color: #2b6cb0;
    font-size: 0.875rem;
    line-height: 1.5;
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const CardTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
`;

const StepsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const StepItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

const StepNumber = styled.div`
  width: 1.75rem;
  height: 1.75rem;
  background-color: #ebf5ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 0.25rem;

  span {
    color: #3182ce;
    font-weight: 600;
    font-size: 0.875rem;
  }
`;

const StepText = styled.p`
  margin: 0;
  color: #4a5568;
  line-height: 1.5;
  text-align: left;
  margin-top: 5px;
`;

const SubBulletList = styled.ul`
  list-style: none;
  margin: 0.75rem 0 0;
  padding-left: 0;
  text-align: left;
`;

const SubBulletItem = styled.li`
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
  color: #4a5568;
  line-height: 1.5;

  &:before {
    content: "•";
    position: absolute;
    left: 0.5rem;
    color: #3182ce;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const FormWrapper = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const InfoCard = () => (
  <InfoCardWrapper>
    <CardHeader>
      <span role="img" aria-label="sparkles" style={{ fontSize: '1.25rem' }}>✨</span>
      <CardTitle>What This Tool Does:</CardTitle>
    </CardHeader>
    
    <StepsList>
      <StepItem>
        <StepNumber><span>1</span></StepNumber>
        <StepText>
          We analyze your website to understand your company's voice and style
        </StepText>
      </StepItem>
      
      <StepItem>
        <StepNumber><span>2</span></StepNumber>
        <StepText>
          Research your chosen topic thoroughly using AI
        </StepText>
      </StepItem>
      
      <StepItem>
        <StepNumber><span>3</span></StepNumber>
        <StepText>
          Generate multiple pieces of content:
          <SubBulletList>
            <SubBulletItem>A comprehensive blog post</SubBulletItem>
            <SubBulletItem>A LinkedIn post optimized for engagement</SubBulletItem>
            <SubBulletItem>A tweet ready for sharing</SubBulletItem>
          </SubBulletList>
        </StepText>
      </StepItem>
    </StepsList>
  </InfoCardWrapper>
);

function Home() {
  const [direction, setDirection] = useState('next');
  const [formData, setFormData] = useState({
    companyName: '',
  });
  const [outputData, setOutputData] = useState(null);
  const [lastInputData, setLastInputData] = useState(null);
  const [usageData, setUsageData] = useState(null);

  const handleUsageData = (data) => {
    setUsageData(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOutput = (data) => {
    if (data && data.result) {
      setOutputData(data.result);
      setLastInputData({
        website_url: formData.companyName,
        topic: formData.topic
      });
    } else {
      console.error('No result found in data');
      alert('No result found in response data');
    }
  };

  const handleBack = () => {
    setOutputData(null);
    setLastInputData(null);
    setFormData({
      companyName: '',
    });
  };

  const handleNewPrompt = async (payload) => {
    try {
        //const response = await axios.post('http://localhost:8080/process', payload, {
    const response = await axios.post('https://marketing-llm-316212251926.us-west2.run.app/process', payload, {
        headers: { 'Content-Type': 'application/json' },
      });
      
      if (response.data && response.data.result) {
        setOutputData(response.data.result);
        setLastInputData(payload);
      } else {
        alert('No result found in response data');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while processing your request.');
    }
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <StepWrapper>
          {!outputData ? (
            <StepContent key="step1" direction={direction}>
              <PageLayout>
                <Header>
                  <Title>Auctus AI</Title>
                  <Subtitle>
                    Transform your ideas into professionally crafted content that matches your company's voice
                  </Subtitle>
                </Header>
                
                <ContentRow>
                  <LeftColumn>
                    <InfoCard />
                    <ExampleCard>
                      <p>
                        <strong>Example:</strong> Enter "tesla.com" and topic "electric vehicles vs hybrid cars" to see how it works
                      </p>
                    </ExampleCard>
                  </LeftColumn>
                  <Column>
                    <FormWrapper>
                      <StepOne
                        formData={formData}
                        handleChange={handleChange}
                        handleOutput={handleOutput}
                        onUsageData={handleUsageData}
                      />
                    </FormWrapper>
                  </Column>
                </ContentRow>

                <UsageInfo>
                {usageData ? (
                    <p>🎉 You have <strong>{usageData.remaining_calls}</strong> out of <strong>{usageData.limit}</strong> requests remaining today. Keep exploring!</p>
                ) : (
                    <p>Checking usage limits...</p>
                )}
                </UsageInfo>
              </PageLayout>
            </StepContent>
          ) : (
            <StepContent key="output" direction={direction}>
              <Title>Your Generated Content</Title>
              <OutputDisplay 
                output={outputData}
                onBack={handleBack}
                onSubmit={handleNewPrompt}
                inputData={lastInputData}
              />
            </StepContent>
          )}
        </StepWrapper>
      </Container>
    </>
  );
}

export default Home;