// src/components/StyledComponents.js
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/free-solid-svg-icons';

/* Fade-in animation */
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

/* Slide-out animation */
const slideOutLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

/* Slide-in animation */
const slideInRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

/* Container for the entire app */
export const Container = styled.div`
  background-color: #FFF9C4; /* Soft, warm yellow */
  min-height: 80vh;
  padding: 40px 20px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

/* Title of the app */
export const Title = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 0px;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically, optional */
  margin-top: 0px;
  height: 60vh; /* Optional: make the container full height */
  background-color: #FFF9C4; /* Example background */
`;

/* Styled form */
export const Form = styled.form`
  background-color: #ffffff;
  padding: 32px;
  border-radius: 16px; /* Softer rounded corners */
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  animation: ${fadeIn} 0.5s ease-in-out;
`;

/* Styled label */
export const Label = styled.label`
  display: block;
  margin: 16px 0 8px;
  color: #555;
  font-weight: 500;
`;

/* Styled input */
export const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
`;

/* Styled textarea */
export const TextArea = styled.textarea`
  width: 100%;
  height: 120px;
  padding: 12px 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
`;

/* Styled select */
export const Select = styled.select`
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
`;

/* Styled file upload input */
export const FileUpload = styled.input.attrs({ type: 'file' })`
  margin-top: 8px;
  font-size: 16px;
`;

/* Styled button with icon */
export const Button = styled.button`
  background-color: #81C784; /* Soft green */
  border: none;
  color: white;
  padding: 14px 28px;
  font-size: 16px;
  margin-top: 24px;
  cursor: pointer;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  text-decoration: none;

  &:hover {
    background-color: #66BB6A;
  }

  svg {
    margin-right: 8px;
  }
`;

/* Animation wrappers */
export const StepWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StepContent = styled.div`
  position: absolute;
  width: 100%;
  animation: ${({ direction }) =>
      direction === 'next' ? slideInRight : slideOutLeft}
    0.5s forwards;
`;

/* Export FontAwesomeIcon and icon for use in components */
export { FontAwesomeIcon, faMagic };